<template>
<div>
      <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top navbar-top">
        <router-link :to="{ name: 'Start' }" class="navbar-brand mr-4">
          <img :src="baseUrl + '/lg.jpg'" width="107.5" height="43" />
        </router-link>
        <b-navbar-brand>Edge Analytics</b-navbar-brand>
      </nav>
  <div v-if="!permissions.app" class="m-4 p-4 text-centre"><h1>No access permissions</h1></div>
  <div v-if="permissions.app" class="app-background" style="min-height: 800px">
    <router-view></router-view>
  </div>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'Analytics app start', action: 'open Analytics app' })
    this.permissions.app = ac.can(this.user.acgroups).readAny('appanalyticsdemo').granted
  },
  data () {
    return {
      baseUrl: this.$config.BASE_URL,
      permissions: {}
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.app-background {
  background-image: url('/img/edge.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.app-background-algo {
  background-image: url('/img/electronic.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.app-background-ut {
  background-image: url('/img/uk_background_image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

a, .nav-link {
  color: white;
}
a:hover, .nav-link:hover {
  color: white;
}
</style>
